<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 18:41:17
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 12:09:16
-->
<template>
  <div class="ArtItem" @click="enterDetail">
    <div class="title">{{ defaultItem.articleName || $t("Detail.noName") }}</div>
    <div class="remark">{{ defaultItem.articleDescribe }}</div>
    <div class="updateTime">{{ fomateDateTime }}</div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "ArtItem",

  props: {
    defaultItem: {},
    usageCategoryId: {},
    categoryName: {},
    GroupName: {},
    index: {},
    usageGroupId: {},
  },
  data() {
    return {};
  },
  computed: {
    fomateDateTime() {
      return dayjs(this.defaultItem.updateTime).format("YYYY/MM/DD HH:mm:ss");
    },
  },
  created() {},
  mounted() {},
  methods: {
    enterDetail() {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: {
          articleId: this.defaultItem.articleId,
          usageCategoryId: this.usageCategoryId,
          usageGroupId: this.usageGroupId,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ArtItem {
  padding: 0.6154rem 0.4103rem;
  background-color: #fff;

  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  .title {
    font-size: 0.4359rem;
    color: var(--theme-color);
    line-height: 1.3;
    margin-bottom: 0.2051rem;
    font-weight: 400;
  }
  .remark {
    font-size: 0.3846rem;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .author {
    margin-top: 0.4615rem;
  }
  .updateTime {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
    margin-top: 0.2051rem;
  }
  .active {
    text-decoration: underline;
  }
}
</style>
