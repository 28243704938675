<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-24 16:24:29
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-04 14:08:30
-->
<template>
  <div class="ListPages">
    <div class="core">
      <Breadcrumb :data="Breadcrumbdata" />
    </div>
    <div class="content">
      <div class="type-info">
        <div class="type-header">
          <img class="type-icon" :src="data.categoryIcon" />
          <span class="type-title">{{ data.categoryName }}</span>
        </div>

        <div class="type-remark">{{ data.categoryDescribe }}</div>
        <div
          class="type-art-num"
        >{{ $t("Collections.total") + countArticle + $t("Collections.nums") }}</div>
      </div>
      <!-- 未分组 -->
      <div class="no-group" v-if="defaultGroup.articles">
        <div class="group-list">
          <ArtItem
            class="artItem"
            v-for="(item, index) in defaultGroup.articles"
            :key="index"
            :defaultItem="item"
            :index="index"
            :usageCategoryId="usageCategoryId"
            :GroupName="item.usageGroupName"
            :categoryName="data.categoryName"
            :usageGroupId="defaultGroup.usageGroupId"
          ></ArtItem>
        </div>
      </div>
      <!-- 分组 -->

      <div class="has-group" v-for="(j, index) in customGroup" :key="index">
        <div class="group-name">{{ j.usageGroupName }}</div>
        <div class="group-list">
          <ArtItem
            class="artItem"
            v-for="(item, index) in j.articles"
            :key="index"
            :defaultItem="item"
            :usageCategoryId="usageCategoryId"
            :GroupName="item.usageGroupName"
            :categoryName="data.categoryName"
            :usageGroupId="j.usageGroupId"
          ></ArtItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "./module/Breadcrumb.vue";
import ArtItem from "./module/ArtItem";
export default {
  name: "ListPages",
  components: { ArtItem, Breadcrumb },
  data() {
    return {
      Breadcrumbdata: [],
      data: {},
      defaultGroup: {},
      customGroup: {},
      usageCategoryId: {},
      countArticle: 0,
    };
  },
  computed: {
    language() {
      return this.$store.state.defaultlanguage;
    },
  },
  watch: {
    language() {
      this.getUsageDetail();
    },
  },
  methods: {
    getUsageDetail() {
      var params = {
        usageCategoryId: this.$route.query.id,
        categoryLanguage: this.language,
      };
      this.$api.getUsageDetail(params).then((res) => {
        if (res.code == 200) {
          this.data = res.data;
          this.defaultGroup = res.data.defaultGroup;
          this.customGroup = res.data.customGroup.filter(
            (item) => item.articles[0]
          );
          var customGroupCountArticleNum = 0;
          res.data.customGroup.forEach((item) => {
            customGroupCountArticleNum += item.countArticle;
          });
          this.countArticle =
            customGroupCountArticleNum + this.defaultGroup.countArticle;
          this.Breadcrumbdata = [
            {
              name: res.data.categoryName,
              path: "",
            },
          ];
        } else {
          // this.$message({
          //   type: "error",
          //   message: res.msg,
          //   duration: 1000,
          //   showClose: true,
          // });
          this.$router.replace({
            name: "Error",
          });
        }
      });
    },
  },
  mounted() {
    this.usageCategoryId = this.$route.query.id;
    this.getUsageDetail();
  },
};
</script>
<style scoped lang="scss">
.ListPages {
  .content {
    margin: 0 0.5128rem 0;
    padding: 0.5128rem;
    background: #ebeef1;
    border-radius: 0.1538rem;

    .no-group,
    .has-group {
      .group-list {
        border-radius: 0.1538rem;
        overflow: hidden;
      }
      .ArtItem:last-child {
        box-shadow: 0 0 0;
      }
    }

    .type-info {
      .type-header {
        display: flex;
        align-items: center;
        margin-bottom: 0.3077rem;
        .type-icon {
          width: 0.8205rem;
          height: 0.8205rem;
          margin-right: 0.3077rem;
        }
        .type-title {
          font-size: 0.4359rem;
          font-family: PingFangSC-Regular;
          font-size: 0.4872rem;
          color: #000000;
        }
      }

      .type-remark {
        font-size: 0.3846rem;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 400;
        line-height: 1.4;
      }
      .type-art-num {
        font-size: 0.2821rem;
        color: rgba(0, 0, 0, 0.4);
        letter-spacing: -0.15px;
        font-weight: 400;
        margin: 0.4103rem 0;
      }
    }
    .artItem {
      margin: 0.0513rem 0;
    }
    .has-group {
      .group-name {
        height: 1.0769rem;
        line-height: 1.0769rem;
        font-size: 15px;
        color: #000000;
        font-weight: 500;
      }
    }
  }
}
</style>
